<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <b-form class="mt-2" @submit.prevent="make_collection">
      <b-row>
        <b-col xl=12 lg=12 md="12" sm="12">
          <b-form-group label="Status" label-for="Status" class="mb-2">
            <v-select
              id="Status"
              v-model="nftEdit.Status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
              required
            />
          </b-form-group>
        </b-col>
        <b-col xl="12" lg="12" md="12" sm="12" v-if="nftEdit.Status=='Published'">
          <b-form-group label="Opensea" label-for="Opensea" class="mb-2">
            <b-form-input id="Opensea" v-model="nftEdit.Opensea" placeholder="https://xxx.com" @change="openseaFilter()"/>
          </b-form-group>
        </b-col>
        <!-- <b-col xl=6 lg=6 md="12" sm="12">
          <b-form-group label="Project Name(kor)" label-for="NameKor" class="mb-2">
            <b-form-input id="NameKor" v-model="nftEdit.NameKor" placeholder="프젝명(한글)" required/>
          </b-form-group>
        
        </b-col> -->

        <b-col xl=6 lg=6 md="12" sm="12">
          <b-form-group label="Project Name(eng)" label-for="NameEn" class="mb-2">
            <b-form-input id="NameEn" v-model="nftEdit.NameEn" placeholder="name" required/>
          </b-form-group>
        </b-col>
   
        <b-col xl=6 lg=6 md="12" sm="12">
          <b-form-group label="Chain" label-for="Chain" class="mb-2">
            <v-select
              id="Chain"
              v-model="nftEdit.Chain"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="chainOption"
            />
          </b-form-group>
        </b-col>
       <b-col xl="6" lg="6" md="12" sm="12">
          <b-form-group label="Web" label-for="Web" class="mb-2">
            <b-form-input id="Web" v-model="nftEdit.Web" placeholder="https://xxx.com"/>
          </b-form-group>
        </b-col>

        <b-col xl="4" lg="4" md="12" sm="12">
          <b-form-group label="Twitter" label-for="Twitter" class="mb-2" >
            <b-form-input id="Twitter" v-model="nftEdit.Twitter" placeholder="twitter(auto)" @change="twitterFilter()"/>
          </b-form-group>
        </b-col>
        <b-col xl="4" lg="4" md="12" sm="12">
          <b-form-group label="Discord" label-for="Discord" class="mb-2">
            <b-form-input id="Discord" v-model="nftEdit.Discord" placeholder="discord"/>
          </b-form-group>
        </b-col>
        <b-col xl="4" lg="4" md="12" sm="12">
          <b-form-group label="Telegram" label-for="Telegram" class="mb-2">
            <b-form-input id="Telegram" v-model="nftEdit.Telegram" placeholder="telegram(auto)" @change="telegramFilter()"/>
          </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="12" sm="12">
          <b-form-group label="roadmap" label-for="roadmap" class="mb-2">
            <b-form-input id="roadmap" v-model="nftEditInfo.roadmap" placeholder="RoadMapLink https://xxx.com" />
          </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="12" sm="12">
          <b-form-group label="whtiepaper" label-for="Paper" class="mb-2">
            <b-form-input id="whtiepaper" v-model="nftEditInfo.whitepaper" placeholder="WhitePaperLink  https://xxx.com" />
          </b-form-group>
        </b-col>
        <b-col xl="12" lg="12" md="12" sm="12">
          <label for="textarea-default">your opinion</label>
          <b-form-textarea class="mb-2"
            id="textarea-default"
            placeholder="Textarea"
            rows="3"
            v-model="nftEditInfo.opinion"
          />
        </b-col>
        <!-- <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Featured Image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="file"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    {{ file ? file.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="file"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                  
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col> -->
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    
    <!--/ form -->
  </b-card>
  
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
BFormTextarea} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { addDoc,collection,serverTimestamp } from "firebase/firestore";
import { uploadBytes,ref as imgRef } from 'firebase/storage'
import { db,storage } from "../firebase"

import { uuid } from 'vue-uuid'
import router from '@/router'
import store from '@/store/index'

export default {
  //normal
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    vSelect,
    quillEditor,
    
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusOption: ['Published', 'Upcomming'],
      chainOption : ['ETH','SOLANA','KLAY','TERRA'],
      levelOption : [1,2,3,4,5,6,7,8,9],
      snowOption: {
        theme: 'snow',
      },
    }
  },
  
  setup() {   
    let userName = store.state.myaccount.user.email.split('@')[0]
    //썸네일 사진관련
    // const refInputEl = ref(null)
    // const refPreviewEl = ref(null)
    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })
    
    //form 관련
    let nftEdit = ref({
      NameKor:"",
      NameEn : "",
      Chain : "ETH",
      Status : "Published",
      Twitter : "",
      Discord : "",
      Telegram : "",
      Web : "",
      Opensea : "",
      Thumbnail : "",
      Level : 1,
      CreateDate : serverTimestamp(),
      Writer:userName
    })
    let nftEditInfo = ref({
      whitepaper : "",
      roadmap : "",
      opinion:"",
    })

    //사진관련
    // let file = ref(null);

    const twitterFilter = () =>{
      let value = nftEdit.value.Twitter
      if(value.includes('https://twitter.com/')){
        let first_split = value.split('https://twitter.com/')[1]
        let second_split = first_split.split('?')[0]
        nftEdit.value.Twitter = second_split
      }else if(value.includes('?')){
        let second_split = value.split('?')[0]
        nftEdit.value.Twitter = second_split
      }else{
        //pass
      }  
    }
    const telegramFilter = () =>{
      let value = nftEdit.value.Telegram
      if(value.includes('https://t.me/')){
        let first_split = value.split('https://t.me/')[1]
        nftEdit.value.Telegram = first_split
      }else if(value.includes('t.me/')){
         let first_split = value.split('t.me/')[1]
          nftEdit.value.Telegram = first_split 
      }else{
        //pass
      }  
    }
    const openseaFilter = () =>{
      let value = nftEdit.value.Opensea
      if(value.includes('https://opensea.io/collection/')){
        let first_split = value.split('https://opensea.io/collection/')[1]
        nftEdit.value.Opensea = first_split
      }else if(value.includes('opensea.io/collection/')){
         let first_split = value.split('opensea.io/collection/')[1]
          nftEdit.value.Opensea = first_split 
      }else if(value.includes('collection/')){
         let first_split = value.split('collection/')[1]
          nftEdit.value.Opensea = first_split 
      }else{
        //pass
      }  
      
    }
    
    async function make_collection(){
      nftEdit.value.CreateDate = serverTimestamp() //현재시간
      let UploadRef = null; //글쓰기함수의 지역함수
      try{
          // 스토리지에 넣기
          // if(file.value != null ){
          //   //비동기 함수가 수행전 작업
          //   const folder = 'thumbnail'
          //   const filename = uuid.v1()
          //   const fileNameLength = file.value.name.length;
          //   const lastDotSpot = file.value.name.lastIndexOf('.');
          //   const extention = file.value.name.substring(lastDotSpot+1,fileNameLength).toLowerCase()
          //   const fullpath = folder+'/'+filename+'.'+extention
          //   nftEdit.value.Thumbnail = fullpath //업로드 성공후 
          //   const thumnailRef = imgRef(storage, fullpath);
          //   uploadBytes(thumnailRef, file.value).then((snapshot) => {   
          //     console.log('성공')
          //   });
          // }
          const infodata = {
              CreateDate : serverTimestamp(),
              Follower : null,
          }

          const graph = {
              CreateDate : serverTimestamp(),
              Follower : null
          }
          const minting = {
              CreateDate : serverTimestamp(),
              Follower : null,
          }
          //db에 넣기
          
          UploadRef = await addDoc(collection(db, "nfts"),nftEdit.value);
          await addDoc(collection(db, "nfts", UploadRef.id, "info"),nftEditInfo.value);
          // await addDoc(collection(db, "nfts", UploadRef.id, "discord"),discorddata);
          // await addDoc(collection(db, "nfts", UploadRef.id, "minting"),minting);
          // await addDoc(collection(db, "nfts", UploadRef.id, "book"),book);
          // await addDoc(collection(db, "nfts", UploadRef.id, "map"),map);
          nftEdit.value.id = UploadRef.id
          store.state.nfts.nftData.unshift(nftEdit.value)
      }catch(err){
          console.log(err)
          
      }finally{
        router.push('/nft-list')
      }
  }

    return {
      // refInputEl,
      // refPreviewEl,
      // file,
      // inputImageRenderer,

      nftEdit,
      nftEditInfo,
      make_collection,
      twitterFilter,
      telegramFilter,
      openseaFilter,
      
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
